import { SearchPage } from 'Components'
import TemplatecolorSearchsearchPage from 'Stores/_default-store/Templates/colorSearch/searchPage.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { RelatedColors } from 'Components'
import TemplateproductrelatedColors from 'Stores/rugsavings/Templates/product/relatedColors.rt'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { SimpleColorPaletteDialog } from 'Components'
import TemplatecolorSearchsimplePaletteDialog from 'Stores/_default-store/Templates/colorSearch/simplePaletteDialog.rt'
import { ColorExtractorDialog } from 'Components'
import TemplatecolorSearchcolorExtractorDialog from 'Stores/_default-store/Templates/colorSearch/colorExtractorDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
RelatedColors,
SearchResult,
SearchHeader,
FacetDialog,
SearchBoxDialogButton,
SearchBoxDialog,
SimpleColorPaletteDialog,
ColorExtractorDialog
} 
export const tplMap = {
  'colorSearch/searchPage': TemplatecolorSearchsearchPage,
'FacetPanelContainer': TemplateFacetPanelContainer,
'product/relatedColors': TemplateproductrelatedColors,
'FacetDialog': TemplateFacetDialog,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'SearchBoxDialog': TemplateSearchBoxDialog,
'colorSearch/simplePaletteDialog': TemplatecolorSearchsimplePaletteDialog,
'colorSearch/colorExtractorDialog': TemplatecolorSearchcolorExtractorDialog
};