const paletteColors = [
  ['White', 'ffffff'],
  ['Brown', '4f2907'],
  ['Pink', 'eb6fa4'],
  ['Silver', 'c0c0c0'],
  ['Teal', '008080'],
  ['Beige', 'f5f5dc'],
  ['Gold', 'deaa0d'],
  ['Red', 'c00718'],
  ['Gray', '808080'],
  ['Blue', '1f5e9e'],
  ['Tan', 'dac8a0'],
  ['Yellow', 'ffd246'],
  ['Purple', '67357e'],
  ['Light Blue', '90c1e4'],
  ['Navy', '132b53'],
  ['Taupe', 'b09c82'],
  ['Peach', 'ffa07a'],
  ['Olive', '808000'],
  ['Aqua', '55c2c3'],
  ['Black', '000000'],
  ['Terracotta', 'b24d38'],
  ['Coral', 'ec5746'],
  ['Green', '248a0f'],
  ['Burgundy', '750f17'],
  ['Orange', 'fa760a'],
  ['Dark Green', '204b21'],
];

// product images on mobile and desktop have different selectors, so we use a device-independent element to get image URL -
// JSON with product data, located on the product page
const getImageToExtractColors = () => {
  const dataEl = document.querySelector('#shopify-section-static-product > [data-section-data]');
  if (!dataEl) {
    return null;
  }

  const data = dataEl.getAttribute('data-section-data') || dataEl.innerHTML;

  let productData;
  try {
    productData = JSON.parse(data);
  } catch (error) {
    console.warn(`Sections: invalid section data found. ${error.message}`);
    return null;
  }

  if (!productData) {
    return null;
  }

  return productData.product.featured_image
    .replace(/^\/\//, 'https://')
    .replace(/_\d*x\d*(.\w+)(\?.+)?/, '_400x400$1')
    .replace(/\?.*$/, '');
};

export default {
  platform: 'shopify',
  ColorEngineFeature: true,
  SearchRequestDefaults: {
    pageSize: 24,
  },
  product: {
    getImageToExtractColors,
    searchResultsImageWidth: 400,
    searchResultsImageHeight: 400,
  },
  facets: {
    rangedFacet: { fields: ['price'], name: 'priceFacet' },
    advancedColorFacet: { fields: ['_Color'], name: 'advancedColorFacet', paletteColors },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#product-ajax',
      template: 'colorSearch/searchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'FacetPanel',
      template: 'FacetPanelContainer',
    },
    {
      name: 'ProductColors',
      type: 'RelatedColors',
      location: {
        replace: '.cm_similarcolors_wrap',
        class: 'row cm_similarcolors_wrap',
      },
      template: 'product/relatedColors',
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Relevancy',
        'title': 'Alphabetically, A-Z',
        'title:desc': 'Alphabetically, Z-A',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
        'published_at': 'Date, old to new',
        'published_at:desc': 'Date, new to old',
      },
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initExpandedFacets: ['_Color', 'price'],
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.site-header-search',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SimpleColorPaletteDialog',
      field: '_Color',
      template: 'colorSearch/simplePaletteDialog',
    },
    {
      name: 'ColorExtractorDialog',
      field: '_Color',
      template: 'colorSearch/colorExtractorDialog',
    },
  ],
};
