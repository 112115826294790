import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import colors from 'Addons/colors.json';
import ColorTile from 'Components/smallComponents/colorTile.tsx';
import { toggleColor } from 'Core/actions/request.js';
import { usePopup } from 'Core/hooks/index.js';
import { WeightedColor } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';

const paletteColors = colors.colorStripes.flat();
const noColor = {};
const name = 'SimpleColorPaletteDialog';

const SimpleColorPaletteDialog = ({ template, field }) => {
  const rootRef = useRef(null);
  const { opened, close } = usePopup(name);
  const [{ color: currentColor, term }, setWeightedColor] = useState(noColor);

  const dispatch = useDispatch();

  const resetState = () => setWeightedColor(noColor);
  const submit = () => {
    if (term && field) {
      resetState();
      dispatch(toggleColor({ field, term }, { mayDiscardValue: true }));
    }
    close();
  };

  if (!opened) {
    return null;
  }

  const paletteItems = paletteColors.map((color) => {
    const onClick = () => setWeightedColor(new WeightedColor(color));
    const props = { onClick, color, isSelected: color === currentColor };
    return <ColorTile {...props} key={color} />;
  });
  const palette = <div className="palette-items">{paletteItems}</div>;

  const sample = <ColorTile className="color-sample" color={currentColor} key="sample" />;

  const component = template.call({
    palette,
    sample,
    close: () => {
      resetState();
      close();
    },
    submit,
  });
  return cloneSafe(component, rootRef, {});
};

export default SimpleColorPaletteDialog;
