import { extractColorsFromFile } from 'Modules/serverApi/index.js';

const baseSize = 400;
const minSize = 128; // sync with server side PatchExtractor.partSize

export default function getColorsFromFile({ filename, src }) {
  return rescaleImage(src)
    .then((canvas) => new Promise((res) => canvas.toBlob(res, 'image/jpeg')))
    .then((blob) => extractColorsFromFile(filename, blob));
}

function rescaleImage(src) {
  return new Promise((res) => {
    const canvas = document.createElement('canvas');

    if (!canvas.toBlob) {
      canvas.toBlob = toBlob;
    }

    const context = canvas.getContext('2d');

    const img = new Image();
    img.addEventListener('load', () => {
      const { height, width } = img;
      const scale = width / height;

      let normalWidth = baseSize;
      let normalHeight = baseSize;
      if (scale < 1) {
        normalWidth *= scale;
      } else {
        normalHeight /= scale;
      }

      let upscale = 1;
      if (normalWidth < minSize) {
        upscale = minSize / normalWidth;
      }
      if (normalHeight < minSize) {
        const upscaleH = minSize / normalHeight;
        if (upscaleH > upscale) {
          upscale = upscaleH;
        }
      }

      canvas.width = Math.ceil(normalWidth * upscale);
      canvas.height = Math.ceil(normalHeight * upscale);

      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      res(canvas);
    });
    img.src = src;
  });
}

function toBlob(callback, type = 'image/jpeg', quality) {
  setTimeout(() => {
    const binStr = atob(this.toDataURL(type, quality).split(',')[1]);
    const arr = new Uint8Array([...binStr].map((c) => c.charCodeAt(0)));
    callback(new Blob([arr], { type }));
  });
}
