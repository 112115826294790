
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedColorsRT () {
    return _createElement('div', { 'className': 'product-colors-container' }, '\n  Colors in this product include: ', this.colorTiles, '\n  ', _createElement('a', {
        'href': this.searchLink,
        'target': '_blank'
    }, 'Browse Similar Colors '));
}
        export const componentNames = []