import { WeightedColorsArray } from 'Models/index.ts';
import { POST } from './common/httpClient.js';

const requestConfig = { headers: { 'Content-Type': 'application/octet-stream' } };

export default async function extractColorsFromFile(fileName, fileContent) {
  const params = { fileName };
  try {
    const colors = await POST('color/extract', params, fileContent, requestConfig);
    return new WeightedColorsArray(colors.map((c) => `${c.Color} ${c.Weight} ${c.Name}`));
  } catch (err) {
    console.warn(err || 'Failed to extract colors');
    return new WeightedColorsArray();
  }
}
