import { useSelector } from 'react-redux';

import ColorTile from 'Components/smallComponents/colorTile.tsx';
import { extractedColorsSelector } from 'Core/selectors/product.ts';
import { getUriFromRequest } from 'Modules/converter/index.js';

import type { FC } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { FacetRequest } from 'Models/index.ts';

type Params = {
  colorTiles: JSX.Element;
  searchLink: string;
};

type Props = {
  template: TemplateFunction<Params>;
};

const RelatedColors: FC<Props> = ({ template }) => {
  const extractedColors = useSelector(extractedColorsSelector);

  if (!extractedColors?.length) {
    return null;
  }

  const colorTiles = (
    <div className="cm_related-colors">
      {extractedColors.map(({ color }) => {
        const props = {
          color,
          showSwatch: true,
        };

        return <ColorTile {...props} key={`relatedColorTile-${color}`} />;
      })}
    </div>
  );

  const colorSelection = extractedColors.toFacetValues('_Color') as FacetRequest[];
  const { href: searchLink } = getUriFromRequest({ selection: colorSelection }, { goToSearchPage: true });

  return template.call({ colorTiles, searchLink });
};

export default RelatedColors;
