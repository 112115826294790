import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColorTile from 'Components/smallComponents/colorTile.tsx';
import { replaceManyValues } from 'Core/actions/request.js';
import { usePopup } from 'Core/hooks/index.js';
import { extractorDataSelector } from 'Core/selectors/dialog.js';
import { WeightedColorsArray } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import getColorsFromFile from './getColorsFromFile.js';

const name = 'ColorExtractorDialog';

const ColorExtractorDialog = ({ template, field }) => {
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const { opened, close } = usePopup(name);

  const [extracted, setExtracted] = useState();
  const [selection, setSelection] = useState([]);

  const data = useSelector(extractorDataSelector);

  if (data && !extracted) {
    getColorsFromFile(data).then(setExtracted);
  }

  if (!opened) {
    return null;
  }

  const resetState = () => {
    setExtracted(null);
    setSelection([]);
  };
  const submit = () => {
    const { length } = selection;
    if (length) {
      const colorsArray = new WeightedColorsArray(selection);
      colorsArray.forEach((c) => (c.weight /= length));
      dispatch(replaceManyValues(colorsArray.toFacetValues(field), { mayDiscardValue: true }));
      resetState();
    }
    close();
  };

  const image = <img className="source" alt="user image" src={data && data.src} />;

  const extractedColors =
    extracted &&
    extracted.map(({ color }) => {
      const isSelected = selection.includes(color);
      const onClick = () => {
        const newSelection = isSelected ? selection.filter((sel) => sel !== color) : selection.concat(color);
        setSelection(newSelection);
      };
      const props = { color, isSelected, onClick };
      return <ColorTile {...props} key={color} />;
    });
  const colors = <div className="colors-container">{extractedColors}</div>;

  const component = template.call({
    image,
    colors,
    close: () => {
      resetState();
      close();
    },
    submit,
  });
  return cloneSafe(component, rootRef, {});
};

export default ColorExtractorDialog;
