export default {
  BUTTON_PRIMARY_CLASS: 'cm_button cm_button__primary button-primary',
  BUTTON_SECONDARY_CLASS: 'cm_button cm_button__secondary button-secondary',

  SORT_BY: 'Sort by&nbsp;',
  LOAD_MORE_TEXT: 'Load more',

  SEARCH_BOX_CLASS: 'site-header-search-form form-fields-inline',
  SEARCH_BOX_INPUT_CLASS: 'form-field-input site-header-search-form-field form-field-filled',
  SEARCH_BOX_FORM_CLASS: 'form-field no-label',
  SEARCH_BOX_PLACEHOLDER: 'What are you looking for?',
  SEARCH_BOX_BUTTON_CLASS: 'site-header-search-button button-primary',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  CURRENT_SEARCH: 'You search:',
  START_OVER: '',
  SELECT_CUSTOM_COLOR: 'SELECT COLOR',
};
